import axiosApi from '@/api/axiosApi';

export const getTypes = () => axiosApi({
    method: 'GET',
    url: '/intserv/gorithm/getTypes',
});

export const findGorithmsByType = (data) => axiosApi({
    method: 'POST',
    url: '/intserv/gorithm/findGorithmsByType',
    data
});

export const details = (id) => axiosApi({
    method: 'GET',
    url: `/intserv/gorithm/details/${id}`
});

export const findGorithmsByProvider = (providier) => axiosApi({
    method: 'GET',
    url: `/intserv/gorithm/findGorithmsByProvider/${providier}`
});

export const findIconSrcByProviderName = (providier) => axiosApi({
    method: 'GET',
    url: `/intserv/gorithm/findIconSrcByProviderName/${providier}`
});