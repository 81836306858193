<template>
    <div class="ai-page">
        <div class="ai-main">
            <div :class="['ai-nav-head', isScroll ? 'scroll': null]">
                <div style="width: 1424px; margin: 0 auto;">
                    <div class="ai-nav-search">
                        <div class="ai-page-title">{{ $i18n.t(`algorithm.pageTitle`) }}</div>
                        <!-- <div class="search-box">
                            <el-input placeholder="Please input" class="search"></el-input>
                            <div class="button">Search</div>
                        </div>
                        <div style="width: 100px;"></div> -->
                    </div>
                    <div class="ai-nav-option">
                        <span class="ai-nav-option-tag">{{ $i18n.t(`algorithm.navOptionTag`) }}</span>
                    </div>
                </div>
            </div>
            <div class="ai-body">
                <div class="ai-selector-list">
                    <div class="ai-selector-item" v-for="(item, index) in selectors" :key="index">
                        <div class="ai-selector-item-title">
                            <span>{{ $i18n.t(`algorithm.selectorItemTitle['${item.title}']`) }}</span>
                        </div>
                        <div class="ai-selector-item-content">
                            <div class="ai-selector-option" v-for="(option, i) in item.options" :key="i">
                                <el-dropdown v-if="option.dropdownItem">
                                    <span :class="['el-dropdown-link', option.selected ? 'option-clicked': null]" @click="handleClick(option, item.options)">
                                        {{ $i18n.t(`algorithm.tag['${option.tag}']`) }}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu class="category-item-content-box" slot="dropdown">
                                        <div class="category-item-content-ul">
                                            <li :class="['category-item-content-li', typeSelected.includes(dropdownItem.id) ? 'active': null]" :data-id="dropdownItem.id" v-for="(dropdownItem, dropdownIndex) in option.dropdownItem" :key="dropdownIndex" @click="handleCommand(dropdownItem,option,item.options)">
                                                {{  $i18n.t(`algorithm.label['${dropdownItem.label}']`) }}
                                            </li>
                                        </div>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <span v-else :class="['el-dropdown-link', option.selected ? 'option-clicked': null]" @click="handleClick(option, item.options)">
                                    {{ $i18n.t(`algorithm.tag['${option.tag}']`) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="gorithm-box">
                    <div class="gorithm-list">
                        <div class="gorithm-item" v-for="(item, index) in gorithms" :key="index" >
                            <div class="gorithm-item-img">
                                <img :src="item.cover" alt="">
                            </div>
                            <div class="gorithm-item-content">
                                <div class="gorithm-content-title" style="margin-bottom: 10px;">
                                    <img :src="img" v-if="item.strictly === 0" style="height: 20.4px;"/>
                                    <span v-if="item.strictly === 1">HOLOWITS SELECTED</span>
                                </div>
                                <div class="gorithm-content-title" @click="toPage(item.id)">
                                    {{item.name}}
                                </div>
                                <div class="gorithm-content-introductory">
                                    {{item.intro}}
                                </div>
                                <div class="gorithm-content-date">
                                    <span>Release Date: {{item.releaseDate}}</span>
                                    <span>Version Update Date: {{item.versionUpdateDate}}</span>
                                </div>
                                <div class="gorithm-content-tags">
                                    <span v-for="tag in item.tags" :key="tag">{{tag}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="pagination">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="pageParam.current"
                                :page-sizes="[10, 20, 30, 40, 50, 100]"
                                :page-size="pageParam.size"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total">
                            </el-pagination>
                        </div>
                    </div>
                    <!-- <div class="recommendedation">
                        <div class="recommend-title">
                            <span>{{$i18n.t('algorithm.recommendation')}}</span>
                        </div>
                        <div class="recommend-list">
                            <a href="https://www.holowits.com/product-center/detail/IVS/03353WAM" target="_blank" class="recommend-item">
                                <div class="recommend-item-img">
                                    <img src="https://resources.holowits.com.sg/algorithm-icon/e6c2f829f91c455798e902377e37f2de.png" alt="">
                                </div>
                                <div class="recommend-item-content">
                                    <div class="recommend-item-title">
                                        <p title="HWT-IVS1800-C08-4T">HWT-IVS1800-C08-4T</p>
                                    </div>
                                    <div class="recommend-item-introductory" title="4T 64-channel 8-disk intelligent Micro Edge">4T 64-channel 8-disk intelligent Micro Edge</div>
                                </div>
                            </a>
                            <a href="https://www.holowits.com/product-center" target="_blank" class="recommend-item">
                                <div class="recommend-item-img">
                                    <img src="https://resources.holowits.com.sg/algorithm-icon/e6c2f829f91c455798e902377e37f2de.png" alt="">
                                </div>
                                <div class="recommend-item-content">
                                    <div class="recommend-item-title">
                                        <p title="HWT-IVS1800-C08-16T">HWT-IVS1800-C08-16T</p>
                                    </div>
                                    <div class="recommend-item-introductory" title="4T 64-channel 8-disk intelligent Micro Edge">16T 64-channel 8-disk intelligent Micro Edge</div>
                                </div>
                            </a>
                            <a href="https://www.holowits.com/product-center" target="_blank" class="recommend-item">
                                <div class="recommend-item-img">
                                    <img src="https://resources.holowits.com.sg/algorithm-icon/e6c2f829f91c455798e902377e37f2de.png" alt="">
                                </div>
                                <div class="recommend-item-content">
                                    <div class="recommend-item-title">
                                        <p title="HWT-IVS1800-C08-32T">HWT-IVS1800-C08-32T</p>
                                    </div>
                                    <div class="recommend-item-introductory" title="4T 64-channel 8-disk intelligent Micro Edge">32T 64-channel 8-disk intelligent Micro Edge</div>
                                </div>
                            </a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {debounce} from 'lodash';
import {getTypes, findGorithmsByType} from '@/api/gorithmApi'
export default {
    name: 'AlgorithmMallIndex',

    data() {
      return {
        isScroll: false,
        selectors: [],
        gorithms: [],
        typeSelected: [],
        pageParam: {
          'current': 1,
          'extra': {},
          'order': 'descending',
          'size': 10,
          'sort': 'publishDate',
        },
        total: 0,
        img: require('../../assets/image/algorithmstore/Normal format.png')
      };
    },
    computed: {
        typeSelectedCopy() {
            return JSON.parse(JSON.stringify(this.typeSelected))
        }
    },
    watch: {
        typeSelectedCopy: {
            // deep: true,
            handler(n, o) {
                this.findGorithmsByTypes()
            }
        }
    },
    mounted() {
    //   window.addEventListener('scroll', () => this.contentScroll(), false);
      this.getFilterTypes()
      this.findGorithmsByTypes()
    },
    methods: {
      handleSizeChange(value) {
        this.pageParam.size = value
        this.findGorithmsByTypes()
      },
      handleCurrentChange(value) {
        this.pageParam.current = value
        this.findGorithmsByTypes()
      },
      async findGorithmsByTypes() {
        let params = {
          ...this.pageParam,
          model: {
            data: this.typeSelected
          },
        };
        const {data: {data, isSuccess}} = await findGorithmsByType(params)
        if (isSuccess) {
            this.gorithms = data.records
            this.pageParam.size = data.size * 1
            this.total = data.total * 1
        }
      },
      async getFilterTypes() {
        const {data: {data, isSuccess}} = await getTypes()
        if (isSuccess) {
            let obj = {}
            let obj1 = {}
            for (let item of data) {
                obj[item.type] = obj[item.type] ?? [];

                !obj[item.type].includes(item.childType) && obj[item.type].push(item.childType);

                obj1[item.childType] = obj1[item.childType] ?? [];

                let object = JSON.stringify({id: item.id, value: item.filterValue})
                !obj1[item.childType].includes(object) && obj1[item.childType].push(object);
            }
            let result = []
            for (let key in obj) {
                let item = {
                    title: '',
                    options: [{
                        tag: 'All',
                        selected: false
                    }]
                }
                item['title'] = key
                for (let childType of obj[key]) {
                    let option = {}
                    let dropdownItem = obj1[childType].map(i => {
                        let obj = JSON.parse(i)
                        return {
                            id: obj.id,
                            label: obj.value,
                            selected: false
                        }
                    }).filter(i => i.label !== '');
                    option['tag'] = childType;
                    if (dropdownItem.length > 0) {
                        option['dropdownItem'] = dropdownItem
                    }
                    option['selected'] = false
                    option['isAll'] = false
                    item.options.push(option)
                }
                result.push(item)
            }
            this.selectors = result
        }
      },
      toPage(id) {
        // this.$router.push({
        //     path: `/algorithm-details-page/${id}`
        // })
        window.open(`/algorithm-details-page/${id}`, '_blank')
      },
      handleCommand(obj,option,options) {
        // obj.selected = !obj.selected
        if (!this.typeSelected.includes(obj.id)) {
            this.typeSelected.push(obj.id)
            obj.selected = true
        } else {
            this.typeSelected.splice(this.typeSelected.indexOf(obj.id), 1)
            obj.selected = false
        }
        let sum = 0;
        for (let item of option.dropdownItem) {
                if (item.selected) {
                    sum += 1;
                }
        }
        if (sum > 0) {
            option.selected = true
        } else {
            option.selected = false
        }
        options[0].selected = false
      },
      handleClick(value, parentElement) {
        if (value.tag === 'All') {
            for (let item of parentElement) {
                item.selected = false
                item.isAll = false
                if (item.dropdownItem && item.dropdownItem.length) {
                    for (let drop of item.dropdownItem) {
                        this.typeSelected.includes(drop.id) && this.typeSelected.splice(this.typeSelected.indexOf(drop.id), 1);
                        item.selected = false
                    }
                }
            }
            value.selected = true
        } else {
            let {dropdownItem} = value;
            if (value.isAll === false) {
                for (let item of dropdownItem) {
                    this.typeSelected.includes(item.id) && this.typeSelected.splice(this.typeSelected.indexOf(item.id), 1);
                    item.selected = false
                }
                for (let item of dropdownItem) {
                    this.typeSelected.push(item.id)
                    item.selected = true
                }
                value.isAll = true
                value.selected = true
            } else {
                for (let item of dropdownItem) {
                    this.typeSelected.includes(item.id) && this.typeSelected.splice(this.typeSelected.indexOf(item.id), 1);
                    item.selected = false
                }
                value.isAll = false
                value.selected = false
            }
            // 取消 all 按钮的高亮
            parentElement[0].selected = false
        }
        // 当前点击按钮高亮
        // value.selected = true
      },
    //   contentScroll: debounce(function(e) {
    //     const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //     this.isScroll = scrollTop >= 100;
    //   }, 100, {'maxWait': 90, 'leading': false}),
      contentScroll(e) {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        this.isScroll = scrollTop >= 100;
      }
    },
    destroyed() {
        // window.removeEventListener('scroll', () => this.contentScroll())
    }
};
</script>
<style>
#navbar-wrapper {
    transition: 0s ease !important;
}
.page-on-scroll .navbar {
    border-bottom: 0px solid rgb(230 239 243) !important; 
}
.category-item-content-box {
    border: 1px solid #c7000b;
    min-width: 72px;
    border-radius: 2px;
    padding: 2px 0;
    margin-top: -50px;
}
.category-item-content-box .popper__arrow {
    display: none;
}
.category-item-content-ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}
.category-item-content-li {
    line-height: 26px;
    float: left;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    color: #666a75;
    margin: 2px 0;
    font-size: 12px;
}
.category-item-content-li:hover {
    background-color: rgba(199,0,11,.04);
}
.category-item-content-li.active, .category-item-content-li:hover {
    color: #c7000b;
}
</style>
<style lang="less" scoped>
.scroll {
  top: 65px !important;
}
.ai-page {
    background-color: #f7f7f7;
    min-height: inherit;
}
.ai-main {
    width: 1410px;
    margin: 0 auto;

    .ai-nav-head {
        background-color: #ffffff;
        // width: 1410px;
        width: 100vw;
        position: fixed;
        top: 100px;
        left: 0;
        z-index: 1;
        box-shadow: 0px 3px 2px #cccccc40;
        // transition: 0.1s ease;
        .ai-nav-search {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 0;
            .ai-page-title {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
            }

            .search-box {
                display: flex;
                .search {
                    width: 500px;
                    height: 30px;
                    /deep/.el-input__inner {
                        height: 30px;
                        line-height: 30px;
                        border-radius: 0px;
                    }
                }
                .button {
                    height: 30px;
                    line-height: 30px;
                    background-color: #d30303;
                    color: #ffffff;
                    padding: 0 30px;
                }
            }
        }

        .ai-nav-option {
            height: 36px;
            width: 100%;
            .ai-nav-option-tag {
                display: inline-block;
                background-color: #d30303;
                color: #ffffff;
                padding: 0 32px;
                height: 36px;
                line-height: 36px;
            }
        }
    }

    .ai-body {
        // min-height: 100vh;
        padding-top: 120px;

        .ai-selector-list {
            
            .ai-selector-item {
                background-color: #ffffff;
                display: flex;
                .ai-selector-item-title {
                    height: 34px;
                    line-height: 34px;
                    font-size: 14px;
                    color: #333333;
                    font-weight: 600;
                    min-width: 250px;
                    padding-left: 30px;
                }
                .ai-selector-item-content {
                    .ai-selector-option {
                        float: left;
                        height: 34px;
                        line-height: 34px;
                        padding-right: 12px;
                        /deep/.el-dropdown-link {
                            font-size: 13px;
                            padding: 5px 12px;
                            border: 1px solid transparent;
                            border-radius: 2px;
                            position: relative;
                            &:hover {
                                color: #d30303;
                                border: 1px solid #d30303;
                            }
                            &:hover:after {
                                content: '';
                                width: 0;
                                height: 0;
                                border-top: 4px solid #c7000b;
                                border-left: 4px solid transparent;
                                border-right: 4px solid transparent;
                                border-top-right-radius: 2px;
                                position: absolute;
                                right: -3px;
                                top: -1px;
                                transform: rotate(-135deg);
                            }
                        }
                        /deep/.item {
                            line-height: 26px !important;
                            height: 26px !important;
                        }
                        .option-clicked {
                            color: #d30303;
                            border: 1px solid #d30303;
                            &::after {
                                content: '';
                                width: 0;
                                height: 0;
                                border-top: 4px solid #c7000b;
                                border-left: 4px solid transparent;
                                border-right: 4px solid transparent;
                                border-top-right-radius: 2px;
                                position: absolute;
                                right: -3px;
                                top: -1px;
                                transform: rotate(-135deg);
                            }
                        }
                        
                    }
                    // .ai-selector-option+.ai-selector-option {
                    //     margin-left: 10px;
                    //     margin-bottom: 10px;
                    // }
                }
            }
            .ai-selector-item+.ai-selector-item {
                margin-top: 2px;
            }
        }
        
        .gorithm-box {
            margin-top: 20px;
            width: 100%;
            position: relative;
            .gorithm-list {
                width: calc(100%);
                background: #fff;
                box-sizing: border-box;
                .gorithm-item {
                    &+.gorithm-item {
                        margin-top: 20px;
                    }
                    background: #fdf9f0;
                    padding: 40px 50px;
                    .gorithm-item-img {
                        display: inline-block;
                        vertical-align:middle;
                        width: 80px;
                        height: 80px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .gorithm-item-content {
                        display: inline-block;
                        vertical-align:middle;
                        width: calc(100% - 80px - 50px);
                        padding-left: 50px;
                        .gorithm-content-title {
                            color: #333333;
                            font-size: 18px;
                            font-weight: 600;
                            &:hover {
                                color: blue;
                                cursor: pointer;
                            }
                            span {
                                display: inline-block;
                                background-color: #252B3A;
                                padding: 0 8px;
                                height: 28px;
                                line-height: 28px;
                                font-weight: 400;
                                color: #D6AF76;
                                font-size: 12px;
                                margin-right: 12px;
                            }
                        }
                        .gorithm-content-introductory {
                            margin-top: 20px;
                            font-size: 14px;
                            color: #666666;
                        }
                        .gorithm-content-date {
                            margin-top: 20px;
                            color: #666666;
                            span+span {
                                margin-left: 50px;
                            }
                        }
                        .gorithm-content-tags {
                            margin-top: 20px;
                            span {
                                border: 1px solid #BBBBBB;
                                border-radius: 2px;
                                font-size: 12px;
                                color: #777777;
                                padding: 4px 5px 4px 4px;
                            }
                            span+span {
                                margin-left: 20px;
                            }
                        }
                    }
                }
                .pagination {
                    margin-top: 50px;
                    width: 100%;
                    text-align: right;
                }
            }
            .recommendedation {
                width: 268px;
                background: #fff;
                position: absolute;
                top: 0;
                right: 0;
                .recommend-title {
                    height: 50px;
                    line-height: 50px;
                    border-bottom: 1px solid #E6E6E6;
                    span {
                        margin-left: 30px;
                        color: #333333;
                        font-size: 14px;
                    }
                }
                .recommend-list {
                    padding: 20px;
                    .recommend-item {
                        &:not(:first-child) {
                            margin-top: 15px;
                        }
                        display: flex;
                        .recommend-item-img {
                            width: 56px;
                            height: 56px;
                            img {
                                width: 56px;
                                height: 56px;
                            }
                        }
                        .recommend-item-content {
                            margin-left: 10px;
                            .recommend-item-title {
                                font-size: 14px;
                            }
                            .recommend-item-introductory {
                                color: #666666;
                                font-size: 14px;
                            }
                        }
                    }
                    
                }
            }

        }
    }
}
// /deep/.el-dropdown-menu {
//     .el-dropdown-menu__item {
//         line-height: 26px !important;
//     }
// }
</style>